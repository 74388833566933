.bg-color-primary-1 {
  background-color: $color-primary-1 !important;
}

.bg-color-primary-2 {
  background-color: $color-primary-2 !important;
}

.bg-color-primary-3 {
  background-color: $color-primary-3 !important;
}

.bg-color-primary-4 {
  background-color: $color-primary-4 !important;
}

.card-view {
  display: block;
  background: #ffffff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  .card-header {
    padding: 0;
    margin: 0;
    display: block;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    a, img {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    a{
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .card-movie-content {
    position: relative;
    padding: 10px 0;
    margin: 0;

    .ratings {
      position: absolute;
      top: -22px;
      padding: 0;
      display: block;
      background-color: $color-primary-4;
      border-radius: 5px 5px 0 0;
      margin: 0 auto;
      left: 0;
      right: 0;
      width: 72px;
      text-align: center;
      color: #FFF;
      span {
        color: yellow;
      }
    }

    .movie-running-time {
      label {
        display: block;
        color: rgba(0, 0, 0, 0.5);
        margin-bottom: .5em;
        font-size: 9px;
      }

      span {
        font-weight: 700;
        font-size: 11px;
      }
    }
  }
}
